<style lang="scss" scoped>
body {
  margin: 0 auto;
}

#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  // background: rgb(194, 194, 194);
  background-image: url("./assets/bg.png");
  background-size: cover;
}
</style>

<template>
  <div id="app" @dragstart.prevent @selectstart.prevent @contextmenu.prevent>
        <router-view></router-view>
  </div>
</template>

<script>
import Vue from 'vue';
export default {
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
