// const console = {
//   log: () => { }
// }

import { Utils } from "run-scene-v2";
import { isNavigationFailure } from "vue-router";
import router from "../../router";
import bus from "./../../lib/bus";
// 声明变量
let camera, scene, controls, renderer2, renderer, dom, t, p, runScene, Bus;
// 工具
const { getMacro } = Utils;
console.log(Utils, 'Utils');

// 拿资源
const setAssets = (assets) => {
  camera = assets.camera;
  scene = assets.scene;
  controls = assets.controls;
  renderer = assets.renderer;
  dom = assets.engineDom;
  t = assets.t;
};

//  页面接口总出口
function Change(runScene, onDone) {
  setAssets({ ...runScene.assetsEx.get(), t: this, runScene });

  t.runScene = runScene;

  t.onDone = onDone;

  // 场景事件
  this.events = null;
  // 工具
  this.tool = null;

  // 最后一帧加载回调
  t.runScene.on("loaded", async () => {
    this.events = new Events();

    this.tool = new Tool();

    // // 支架 模型选择
    // this.choiceModel = new ChoiceModel();
    // this.choiceModel.init();

    this.dispose = new Dispose();
    this.dispose.init();

    this.size = new Size();
    this.size.init();

    // 灯光
    // this.light = new Light();
    // this.light.init();

    // 最小距离
    controls.minDistance = 100;
    console.log(controls);
    // 最大距离
    controls.maxDistance = 350;
    controls.enablePan = false;
    // 基本配置
    (() => {
      t.runScene.script.playAll();

      // 入场动画
      t.runScene.cameraEx.setTemp("初始视角", { time: 2 });

      // 场景的基本配置
      controls.maxPolarAngle = Math.PI;
      // 加载回调
      t.onDone();

      t.runScene.cb.controls.autoRotate.resume.on(() => {
        // this.dispose.reset();
        // this.size.hiddenSize();
        t.runScene.snapshot.set('groupBegin', {
          onDone: () => {
            t.runScene.cameraEx.setTemp("初始视角", { time: 2 });
          }
        });
        t.runScene.cameraEx.setTemp("初始视角", { time: 2 });

        router.replace({
          name: "feature"
        })
        const model = t.runScene.modelEx.getModel("尺寸");
        if (!model.visible) return;
        model.children.map((child) => {
          Utils.anima(
            {
              x: child.scale.x,
              y: child.scale.y,
              z: child.scale.z,
            },
            {
              x: 0,
              y: 0,
              z: 0,
            },
            0.5,
            (data) => {
              child.scale.x = data.x;
              child.scale.y = data.y;
              child.scale.z = data.z;
            },
            () => {
              model.visible = false;
            }
          );
        });
      })
      window.addEventListener('click', () => {
        t.runScene.controlsEx.onEnd()

      })
      // runScene.assetsEx.render3.setSize(1920, 1080)

      let et = runScene.modelEx.getModel("ET_G2").position.clone();
      let bq = runScene.modelEx.getModel("标签");
      runScene.cb.controls.change.on(() => {
        const { camera } = runScene.assetsEx.get()
        const distance = Utils.getDistance(camera.position.clone(), et)
        if (distance < 200) {
          bq.traverse((child) => {
            child.visible = false
          })
        } else {
          bq.traverse((child) => {
            child.visible = true
          })
        }
      })
      const modelDiv = t.runScene.modelEx.getModel("标签");
      modelDiv.children.map((child) => {
        const sprite = child.children[0]
        if (sprite.type !== 'Sprite') {
          // sprite.scale.set(0.1,0.1,0.1)
          sprite.scale.set(0.15, 0.15, 0.15)

          // sprite.scale.set(0.2, 0.2, 0.2)
        } else {
          sprite.material.toneMapped = false
          sprite.material.alphaToCoverage = true
        }
      })
      //  Utils.anima(
      //   {
      //     y: -3.15,
      //   },
      //   {
      //     y: 3.15,
      //     repeat: -1,
      //     // repeat: true,
      //     ease: "none",
      //   },
      //   40,
      //   (map) => {
      //     modelDiv.rotation.y = map.y;
      //     modelDiv.rotation.z += Math.random() * 0.001;
      //   }
      // );

    })();
  });

  // 销毁
  this.dispose = () => runScene.dispose();
}

// 选择 模型
class ChoiceModel {
  // 电池模组 模型
  batteryModelMap = {};
  init() {
    // 获取模型
    this.getModel();
    // 切换模型
    bus.$on("switchMesh", this.choose.bind(this));
  }

  // 获取 模型
  getModel() {
    const tagsId = t.runScene.tags.get("LFP3000/HV");
    tagsId.map((childId) => {
      const model = t.runScene.modelEx.getById(childId);
      const childrenName = model.name;
      this.batteryModelMap[childrenName] = null;
      this.batteryModelMap[childrenName] = model;
    });
  }

  // 选择
  choose(chooseName) {
    // 显示隐藏 对应电池
    Object.keys(this.batteryModelMap).map((modelName) => {
      const model = this.batteryModelMap[modelName];
      if (modelName === chooseName) {
        model.visible = true;
      } else {
        if (!model.visible) return;
        model.visible = false;
      }
    });

    // 重置 时间线状态
    // t.dispose.resetGroup("组1"); 

    // 聚焦 动画
    this.focus(chooseName);
  }

  // 聚焦 动画
  focus(chooseName, time = 1) {
    console.log(chooseName, 'chooseName');
    const animaName = `${chooseName}-pc初始`;
    t.runScene.cameraEx.setTemp(animaName, { time: 1 });
  }
}

// 拆解相关
class Dispose {

  init() {
    // 拆解
    bus.$on("displayMesh", this.dispose.bind(this));
    // 恢复
    bus.$on("unDisplayMesh", this.reset.bind(this));
  }

  // 拆解  bus
  async dispose() {
    // 指定 时间线
    await t.runScene.cameraEx.setTemp("爆炸视角", { time: 2 });
    t.runScene.snapshot.set('groupEnd')
    t.runScene.controlsEx.onEnd();

  }

  // 恢复 bus
  reset() {
    // 指定 时间线
    t.runScene.snapshot.set('groupBegin', {
      onDone: () => {
        t.runScene.cameraEx.setTemp("初始视角", { time: 2 });
      }
    });

    t.runScene.controlsEx.onEnd();

  }

  resetAll() {
    t.runScene.cameraEx.setTemp("初始视角", { time: 2 });
    t.runScene.snapshot.set('groupBegin');


  }

  resetAllNow() {
    t.runScene.timeLineEx.resetGroup("LFP3000/HV");
    t.runScene.timeLineEx.resetGroup("LFP5000");
    t.runScene.timeLineEx.resetGroup("LFP5-10kWHLV");
    scene.globalConfig.setGlow(false);
  }

  // 重置 时间线状态
  resetGroup(sceneName) {
    t.runScene.timeLineEx.resetGroup(sceneName);
  }
}

// 灯光
class Light {
  directionaLight = null;

  // 初始化
  init() {
    this.getModel();

    // 灯跟随移动
    t.runScene.cb.render.add("lightMove", () => {
      // this.directionaLight.position.copy(t.runScene.assetsEx.camera.position);
      // t.runScene.lightEx.update(this.directionaLight)
    });
  }

  // 获取 模型
  getModel() {
    this.directionaLight = t.runScene.modelEx.getModel("PointLight");
  }
}

// 尺寸
class Size {

  init() {
    // 展示 尺寸
    bus.$on("showSize", this.showSize.bind(this));
    // 隐藏 尺寸
    bus.$on("hiddenSize", this.hiddenSize.bind(this));

    //切换镜头接口
    bus.$on("changeCamera", this.changeCamera.bind(this));

  }

  changeCamera(name) {
    t.runScene.cameraEx.setTemp(name, { time: 2 });
  }
  // 展示尺寸
  showSize() {
    t.runScene.cameraEx.setTemp("尺寸视角", { time: 2 });
    t.runScene.controlsEx.onEnd();

    const model = t.runScene.modelEx.getModel("尺寸");
    model.children.map((child) => {
      Utils.anima(
        {
          x: 0,
          y: 0,
          z: 0,
        },
        {
          x: 1,
          y: 1,
          z: 1,
        },
        0.5,
        (data) => {
          child.scale.x = data.x;
          child.scale.y = data.y;
          child.scale.z = data.z;
          if (data.x >= 0.1) {
            if (model.visible) return;
            model.visible = true;
          }
        }
      );
    });
  }

  // 隐藏尺寸
  hiddenSize() {
    t.runScene.cameraEx.setTemp("初始视角", { time: 2 });
    t.runScene.controlsEx.onEnd();


    const model = t.runScene.modelEx.getModel("尺寸");
    if (!model.visible) return;
    model.children.map((child) => {
      Utils.anima(
        {
          x: child.scale.x,
          y: child.scale.y,
          z: child.scale.z,
        },
        {
          x: 0,
          y: 0,
          z: 0,
        },
        0.5,
        (data) => {
          child.scale.x = data.x;
          child.scale.y = data.y;
          child.scale.z = data.z;
        },
        () => {
          model.visible = false;
        }
      );
    });
  }

}

// 工具方法 ( 可封的公共方法 )
class Tool {
  // 相机 动画
  cameraAnima(position, time = 1, fn) {
    t.events.closeAnimaAtStart.cameraAnima = Utils.anima(
      {
        cx: t.runScene.assetsEx.camera.position.x,
        cy: t.runScene.assetsEx.camera.position.y,
        cz: t.runScene.assetsEx.camera.position.z,
        tx: t.runScene.assetsEx.controls.target.x,
        ty: t.runScene.assetsEx.controls.target.y,
        tz: t.runScene.assetsEx.controls.target.z,
      },
      {
        ...position,
      },
      time,
      (data) => {
        t.runScene.assetsEx.camera.position.set(data.cx, data.cy, data.cz);
        t.runScene.assetsEx.controls.target.set(data.tx, data.ty, data.tz);
        t.runScene.assetsEx.camera.updateProjectionMatrix();
        t.runScene.assetsEx.controls.update();
      },
      () => {
        fn && fn();
      }
    );
  }
  // 模型 透明度 渐变动画
  showAnima(info) {
    const { model, isShow, time, cb, opacity } = info;
    const models = [];
    model.traverse((m) => {
      if (m.type === "Group") return;
      if (m.type === "Object3D") return;
      m.material.transparent = true;
      isShow ? (m.material.opacity = 0) : null;
      models.push(m);
    });
    if (isShow) model.visible = isShow;
    Utils.anima(
      { opc: isShow ? 0 : opacity || 1 },
      { opc: isShow ? opacity || 1 : 0 },
      time,
      (data) => {
        models.map((m) => (m.material.opacity = data.opc));
      },
      () => {
        if (!isShow) model.visible = isShow;
        cb && cb();
      }
    );
  }
}

// 基本事件
class Events {
  downPosition = { x: 0, y: 0 };

  closeAnimaAtStart = { enterAnima: "" };

  constructor() {
    t.runScene.assetsEx.controls.addEventListener("start", this.controlStart);
    t.runScene.cb.model.setSelect.add(
      "trigger-click",
      this.triggerClick.bind(this)
    );
    t.runScene.cb.events.pointer.down.add("trigger", (e) =>
      t.runScene.modelEx.selectNull()
    );
    document.addEventListener("click", (e) => { });

    // this.autoRotate();
  }

  autoRotate() {
    t.runScene.cb.render.add("autoRotate", () => {
      t.runScene.assetsEx.controls.autoRotate = true;
      t.runScene.assetsEx.controls.autoRotateSpeed = 2;
      t.runScene.assetsEx.controls.update();
    });
  }

  triggerClick = (model) => {
    if (!model) return;
    console.log("点击的模型:", model);
    console.log(
      `cx:${camera.position.x}, cy:${camera.position.y}, cz:${camera.position.z}, tx:${controls.target.x}, ty:${controls.target.y}, tz:${controls.target.z} `,
      "位置"
    );
    console.log(t.runScene.modelEx.getModel("DirectionalLight_2"), "灯");
  };

  // 清除动画
  controlStart = () => {
    this.closeAnmia();
  };

  // 暂停动画 并清空内容 item就是那个动画
  closeAnmia() {
    Object.values(this.closeAnimaAtStart).map((i) => i && i.kill());
  }

  dispose() {
    controls.removeEventListener("start", this.controlStart);
  }
}

export default Change;
