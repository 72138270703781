<template>
  <div class="three-scene" ref="three-scene" onselectstart="return false;">
    <div @pointerdown="(e) => e.stopPropagation()" class="btn"></div>
    <!-- <button @click="test">截图测试</button> -->
    <div
      class="left-dom s1"
      v-for="item in leftList"
      :class="item.id"
      :style="`opacity:${tagIsShow ? '1' : '0'}`"
    >
      <div class="dom-text">{{ item.text }}</div>
      <div class="dom-img">
        <img :src="item.img" alt="" />
      </div>
    </div>
    <div
      class="right-dom s1"
      v-for="item in rightList"
      :class="item.id"
      :style="`opacity:${tagIsShow ? '1' : '0'}`"
    >
      <div class="dom-img">
        <img :src="item.img" alt="" />
      </div>
      <div class="dom-text">{{ item.text }}</div>
    </div>
  </div>
</template>

<script>
import store from "../../store/index";
// chang 事件 实例
let sceneChange = null;
// 场景
let scene = null;
import Change from "./Change";
import { RunScene } from "run-scene-v2";
import bus from "./../../lib/bus";
export default {
  name: "three-scene",
  data() {
    return {
      isShowDom: true,
      leftList: [
        {
          id: "DM-5",
          text: "Integrated dry contact for external loads",
          img: require("@/assets/features/1.svg"),
        },
        {
          id: "DM-9",
          text: "Peak shaving",
          img: require("@/assets/features/2.svg"),
        },
        {
          id: "DM-1",
          text: "Integrated AFCI",
          img: require("@/assets/features/3.svg"),
        },
        {
          id: "DM-2",
          text: "IP66 ingress protection",
          img: require("@/assets/features/4.svg"),
        },
      ],
      rightList: [
        {
          id: "DM-10",
          text: "integrated smart meter",
          img: require("@/assets/features/10.svg"),
        },
        {
          id: "DM-3",
          text: "Plug & Play",
          img: require("@/assets/features/5.svg"),
        },
        {
          id: "DM-4",
          text: "Elegant and compact design",
          img: require("@/assets/features/6.svg"),
        },
        {
          id: "DM-6",
          text: "Maximum 16 A DC input current per string",
          img: require("@/assets/features/8.svg"),
        },
        {
          id: "DM-7",
          text: "Strong power backup supply",
          img: require("@/assets/features/7.svg"),
        },
        {
          id: "DM-8",
          text: "100% unbalanced output",
          img: require("@/assets/features/9.svg"),
        },
      ],
      // hover 的 部件
      choosePartId: {
        name: "",
        isHover: false,
        isClick: false,
      },
    };
  },
  watch: {},
  mounted() {
    // 加载场景
    this.loadScene();
    bus.$on("hoverMenu", this.hoverChangeClassList);
  },
  computed: {
    tagIsShow() {
      return this.$route.name == "feature";
    },
  },
  methods: {
    test() {
      bus.$emit("screenshot", 500, 500);
    },
    // 加载场景
    loadScene() {
      scene = new RunScene({
        msg: {
          // show: true,
        },
        // showFps: true,
        //coverSameId: true,
        //instanceClone: false,
        // render3: true,
        // render2: true,
        renderConfig: {
          // 是否允许设置模型位置后自动渲染最新效果
          matrixAutoUpdate: true,
          scriptFrame: 60,
        },
      })
        .load({
          path:
            // "https://3d-editor-2-1303915342.cos.ap-shanghai.myqcloud.com/202305082017499858391001202374.glb",
            // "http://192.168.3.8:8080/file?path=project/linkpoint/&key=202305082017499858391001202374",
            process.env.VUE_APP_BASE_ASSETS + "assets/s.glb",
          dom: this.$refs["three-scene"],
        })
        .on("complete", () => {
          this.$emit("load");
        });
      console.log(scene, "scene");
      // 实例化场景
      sceneChange = new Change(scene, this.onDone);
    },
    // 加载完回调
    onDone() {
      console.log("场景加载完毕~");
    },
    // 打印点击到的模型
    logClickModel(model) {
      console.log("点击的模型为:", model.name);
    },
    // 聚焦 部件
    focuseParts(partId) {
      const id = partId.substring(0, partId.indexOf("点位") - 4);
      bus.$emit("switchMeshChild", store.state.mesh, id);
    },
    // 移入
    hoverPartEnter(e, paetId) {
      // this.choosePartId.isClick  = false;
      const id = paetId.substring(0, paetId.indexOf("点位") - 4);
      // 部件 id
      this.choosePartId.name = id;
      // 已经 移入
      this.choosePartId.isHover = true;

      bus.$emit(
        "partHoverEvents",
        this.choosePartId.name,
        this.choosePartId.isHover,
        true
      );
    },
    // 移出
    hoverPartLeave(e, paetId) {
      const id = paetId.substring(0, paetId.indexOf("点位") - 4);
      this.choosePartId.name = id;
      if (this.choosePartId.isClick) {
        // 移入
        this.choosePartId.isHover = true;

        this.choosePartId.isClick = false;
        bus.$emit(
          "partHoverEvents",
          this.choosePartId.name,
          this.choosePartId.isHover,
          false
        );
      } else {
        // 移出
        this.choosePartId.isHover = false;
      }

      bus.$emit(
        "partHoverEvents",
        this.choosePartId.name,
        this.choosePartId.isHover
      );
    },
  },
  // 场景自带销毁
  unmounted() {
    sceneChange && sceneChange.dispose();
  },
};

// 导出场景 资源
export { sceneChange, scene };
</script>

<style scoped lang="scss">
// .s1 {
//   zoom: 2;
// }
.left-dom,
.right-dom {
  padding: 0 16px;
  width: 240px;
  height: 54px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(4px);
    border-radius: 43px;
  }
  .dom-text {
    position: relative;
    z-index: 2;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.8);
  }
  .dom-img {
    position: relative;
    z-index: 2;
    width: 40px;
    height: 32px;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.left-dom {
  justify-content: flex-end;
  .dom-text {
    margin-right: 8px;
    text-align: right;
  }
}
.right-dom {
  justify-content: flex-start;
  .dom-text {
    margin-left: 8px;
    text-align: left;
  }
}
.three-scene {
  width: 100%;
  height: 100%;
}

/*比如：在父元素字体大小为 12px 的容器内绘制图形交互*/
@function pxToEm($px) {
  @return ($px/12) + em;
}

.three-scene .btn {
  position: absolute;
  z-index: 2;
}

.three-scene .show {
  opacity: 1 !important;
}

.three-scene .none {
  opacity: 0 !important;
}

.three-scene .block {
  display: block !important;
}

.sprites {
  position: absolute;
  width: 200px;
  height: 200px;
  background-color: red;
  opacity: 0;
}

.showOpacity {
  opacity: 1 !important;
}

.partSprite {
  opacity: 0;
  width: 40px;
  height: 40px;
  z-index: 3;
  border-radius: 50%;
  position: relative;

  .outerCircle {
    width: 100%;
    height: 100%;
    font-size: 12px;
    background: rgba(255, 255, 255, 0.3);
    border: 0.1px solid rgba(255, 255, 255, 0.8);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: -1;
  }

  .InnerRing {
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    position: absolute;
    z-index: -1;
  }

  .text {
    left: 200%;
    width: 0px;
    // background-color: red;
    margin-top: -100%;
    border-radius: 60px;
    transition: all 0.5s;
    overflow: hidden;
    z-index: 4;

    > div {
      // width: 100%;
      // height: 100%;
      transition: all 0.4s;
      border-radius: 60px;
      margin: 8px 20px;
      // width: fit-content;
      height: 40px;
      margin-left: -100px;
      opacity: 0;
      color: rgba(0, 0, 0, 0.8);
      background: rgba(255, 255, 255, 0.8);
      border-radius: 60px;
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      // display: flex;
      // align-items: center;
      // justify-content: center;
    }
  }
}

.hoverPart {
  .outerCircle {
    opacity: 0;
  }

  .InnerRing {
    opacity: 0;
  }

  .text {
    // left: 200%;
    width: fit-content;
    display: block;
    white-space: nowrap;

    > div {
      // margin: 8px 20px;
      margin-left: 0%;
      padding: 0 20px;
      order: 0;
      opacity: 1;
      flex-grow: 0;
      display: flex;
      align-items: center;
    }
  }
}

.partSprite:hover {
  @extend .hoverPart;
}

.hoverPartSprite {
  @extend .hoverPart;
}

button {
  position: absolute;
  z-index: 300;
}
</style>
