<style lang="scss">
.loading{
    position: fixed;
    left:0;
    top:0;
    z-index: 3;
    background: rgb(253,253,253);
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .loading-text{
        font-size: 18px;color: rgba(0, 0, 0, 0.8);
    }
    .loading-icon{
        font-size: 40px;
        color: rgba(0, 0, 0, 0.8);
    }
    .loading-image{
        width: 74px;
        height: 89px;
        background-image: url('./loading-image.png');
        background-size: 100% 100%;
        margin-top: 348px;
        margin-bottom: 4px;
        color: rgba(0, 0, 0, 0.6);
        font-size: 13px;
    }
    .logo{
        width: 130px;
        height: 42px;
        display: flex;
        align-items: center;
        position: absolute;
        img{
            width: 100%;
            
        }
    }
}
</style>

<template>
<div class="loading">

    <div class="logo">
        <img src="../../assets/logos.png" alt="">
    </div>
    <div class="loading-image">
       
    </div>

    <div class="loading-text">
        Product model loading
    </div>
</div>
</template>


<script>
export default{
    name:"loading"
}
</script>